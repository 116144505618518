<template>
  <div>
    <div class="commodity">
      <div class="commodity_Product">
        <div class="commodity_img">

        </div>
        <div class="commodity_information">
          <div class="commodity_name">
            Phi Villa 3-Piece Rattan Outdoor Sectional Sofa - Blue
          </div>
          <div class="commodity_price">
            $ 447.33
          </div>
        </div>
      </div>
      <div class="commodity_Quantity">
        <div class="">
          <el-input-number class="inputnumber" v-model="num" @change="handleChange" :min="1" :max="10" label="描述文字">
          </el-input-number>
        </div>
        <div class="Remove">
          Remove
        </div>
      </div>
      <div class="commodity_Total">
        $ 485.33
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        num: 1
      };
    },
    methods: {
      handleChange(value) {
        console.log(value);
      }
    },
  }

</script>
<style lang="scss" scoped>
  .commodity {
    border-top: 1px solid #DEDEDE;
    display: flex;
    padding-left: 24px;
    padding-top: 15px;
    padding-bottom: 14px;

    .commodity_Product {
      // width: 600px;
      display: flex;

      flex: 4;


      .commodity_img {
        width: 84px;
        height: 84px;
        border-radius: 2px;
        background-color: blue;
      }

      .commodity_information {
        display: flex;
        flex-direction: column;
        padding-left: 16px;

        .commodity_name {
          width: 320px;
          word-wrap: break-word;
          word-break: break-all;
          flex: 9;
          line-height: 20px;
          font-family: PingFangSC, PingFangSC-Medium;
          font-weight: bold;
          font-size: 14px;
          color: #000000;
        }

        .commodity_price{
          color: #FF4D4F;
        }

      }
    }

    .commodity_Quantity {
      flex: 2;
      // width: 210px;
      text-align: center;
      align-items: center;
      justify-content: center;

      .inputnumber {
        // width: 210px;
        width: 150px;
        margin-top: 10px;
      }

      .Remove {
        font-size: 13px;
        margin-top: 8px;
        color: #4d4d4d;
      }
    }

    .commodity_Total {
     flex: 1;
      text-align: center;
      margin-top: 24px;
      word-wrap: break-word;
      word-break: break-all;
    }
  }

  /deep/.el-input-number__increase,
  .el-input-number__decrease {
    background: white;
  }

</style>
