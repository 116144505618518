<template>
  <div>
      <div class="content_title">
        <div class="title_mycart">
          My cart
        </div>
        <div class="title_tip">
          You are eligible for free shipping!
        </div>
      </div>
  </div>
</template>
<script>
export default {
  
}
</script>
<style lang="scss" scoped>
  .content_title{
    display: flex;
    flex-direction: column;
    .title_mycart{
      font-size: 26px;
      font-family: 'PingFangSC';
      font-weight: 500;
    }
    .title_tip{
      font-size: 16px;
      color: #4D4D4D;
      margin-top: 10px;
    }
  }
</style>