<template>
  <div>
    <div class="Expired_content">
      <div class="Expired_title">
        Expired
      </div>

      <div class="Expired_commodity">
        <div class="Expired_Product">


          <div class="Expired_commodity_img">

          </div>

          <div class="Expired_commodity_information">
            <div class="Expired_commodity_information_name">
              Phi Villa 3-Piece Rattan Outdoor Sectional Sofa - Blue
            </div>

            <div class="Expired_commodity_information_price">
              $ 447.33
            </div>
          </div>
        </div>
        <div class="Expired_commodity_remove">
          <div>
            Remove
          </div>
        </div>

        <div class="Expired_commodity_btn">
          <el-button round class="expired_btn">Expired</el-button>
        </div>

      </div>

    </div>
  </div>
</template>
<script>
  export default {

  }

</script>
<style lang="scss" scoped>
  .Expired_content {
    margin-top: 32px;

    .Expired_title {
      font-size: 16px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: bold;
    }
  }

  .Expired_commodity {
    margin-top: 12px;
    background: #ffffff;
    border: 1px solid #dedede;
    padding-top: 16px;
    padding-left: 24px;
    padding-bottom: 16px;
    display: flex;

    .Expired_Product {
      flex: 4;
      display: flex;
    }

    .Expired_commodity_img {
      width: 84px;
      height: 84px;
      background: blue;
    }

    .Expired_commodity_information {
      display: flex;
      flex-direction: column;
      padding-left: 16px;

      .Expired_commodity_information_name {
        width: 320px;
        word-wrap: break-word;
        word-break: break-all;
        flex: 9;
        line-height: 20px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: bold;
        font-size: 14px;
        color: #000000;
      }
    }


    .Expired_commodity_remove {
      flex: 2;
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;
      color: #4d4d4d;
      font-size: 13px;
    }

    .Expired_commodity_btn {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      .expired_btn{
        background: #f3f3f3;
      }
    }
  }

</style>
