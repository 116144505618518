<template>
  <div>
      <div class="Product_title">
          <div class="Product">
            Product
          </div>
          <div class="Quantity">
            Quantity
          </div>
          <div class="Total">
            Total
          </div>
        </div>
  </div>
</template>
<script>
export default {
  
}
</script>
<style lang="scss" scoped>
 .Product_title {
    display: flex;
    font-size: 16px;
    font-weight: 400;
    color: #333333;
    padding-left: 24px;
    padding-top: 15px;
    padding-bottom: 14px;
    line-height: 22px;


    .Product {
      // width: 600px;
      flex: 4;
    }

    .Quantity {
      // width: 210px;
      flex: 2;
      text-align: center;
    }

    .Total {
    flex: 1;
      text-align: center;
    }

  }

</style>
