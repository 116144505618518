<template>
  <div class="main-content-wrap import-list">
    <div class="content">
      <!-- mycard 头部 -->
      <contentTitle></contentTitle>

      <!-- 选购的产品 -->
      <div class="Shopping_content">
        <div style="flex_direction">
          <div class="Product_content">
            <ProductTitle></ProductTitle>
            <!-- 已选项目 -->
            <commodity></commodity>
          </div>

          <!-- 期望的商品 -->
          <Expired></Expired>
        </div>



        <!-- 右侧总价格 -->
        <div class="Total_content">
          <div class="total_title">
            <div class="total_total">
              Total
            </div>
            <div class="total_price">
              $ 452.00
            </div>
          </div>

          <div class="total_top">
            Tax included. <span class="total_size_color">Shipping</span> calculated at checkout
          </div>

          <div class="total_line">

          </div>

          <div class="total_btn">
            <el-button class="Checkout_btn">Checkout</el-button>
          </div>

          <div class="WeAccept">
            We Accept:
          </div>

          <div class="WeAccept_img">
            <div class="WeAcceptimg"></div>
            <div class="WeAcceptimg"></div>
            <div class="WeAcceptimg"></div>
            <div class="WeAcceptimg"></div>
            <div class="WeAcceptimg"></div>
          </div>

        </div>
      </div>








    </div>
  </div>
</template>
<script>
  import contentTitle from './components/content_title'
  import ProductTitle from './components/Product_title'
  import commodity from './components/commodity'
  import Expired from './components/Expired'

  export default {
    name: 'mycard',
    components: {
      contentTitle,
      ProductTitle,
      commodity,
      Expired
    },

  }

</script>
<style lang="scss" scoped>
  .import-list {
    padding-top: 25px;
  }

  .main-content-wrap {
    width: 1300px;
    margin: 0 auto;
    box-sizing: border-box;
  }


  .Product_content {
    width: 900px;
    background: white;
    border: 1px solid #dedede;
    margin-top: 24px;
    display: flex;
    flex-direction: column;
  }

  .Shopping_content {
    display: flex;

    .flex_direction {
      display: flex;
      flex-direction: column;
    }
  }

  .Total_content {
    margin-left: 23px;
    margin-top: 24px;
    width: 376px;
    height: 385px;
    background: #ffffff;
    border: 1px solid #dedede;
    border-radius: 2px;
    padding: 28px;

    .total_title {
      display: flex;
      justify-content: space-between;

      .total_total {
        font-size: 16px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: bold;
        color: #1a1a1a;
      }

      .total_price {
        font-size: 16px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: bold;
        color: #1a1a1a;
      }
    }

    .total_top {
      margin-top: 8px;
      font-size: 14px;

      .total_size_color {
        color: #FF6128;
      }
    }

    .total_line {
      height: 1px;
      background: #e6e8eb;
      margin-top: 24px;
    }

    .Checkout_btn {
      background: #FF6128 !important;
      height: 50px;
      width: 100%;
      margin-top: 24px;
      color: white;
      font-size: 18px;
    }

    .WeAccept {
      margin-top: 32px;
      font-size: 16px;
      font-family: PingFangSC, PingFangSC-Semibold;
      font-weight: 600;
      color: #1a1a1a;
    }

    .WeAccept_img {
      margin-top: 8px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .WeAcceptimg {
        width: 75px;
        height: 53px;
        border: 1px solid #e6e8eb;
        margin-bottom: 8px;
      }
    }

  }

</style>
